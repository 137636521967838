<template>
  <div>
    <el-form :inline="true">
      <el-form-item label="OfferId(s)">
        <el-input v-model="filter.offerIds"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getEventIssueList(0)">Search</el-button>
        <el-button type="primary" @click="changeEventIssueClick">Add</el-button>
        <el-button type="danger" @click="batchDelClick">Delete</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="eventIssueList"
      border
      stripe
      style="width:100%;"
      height="80vmin"
      highlight-current-row
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column label="OfferId" prop="offerId" align="center"></el-table-column>
      <el-table-column label="EventName" prop="eventName" align="center"></el-table-column>
      <el-table-column label="status" prop="status" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.status === 1 ? '开启' : '关闭' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Operator" prop="createBy" align="center"></el-table-column>
      <el-table-column label="Operate Time" prop="createTime" align="center"></el-table-column>
      <el-table-column label="Option" align="center" min-width="100">
        <template slot-scope="scope">
          <el-button type="primary" @click="changeEventIssueClick(scope.row)">Update</el-button>
          <el-button type="danger" @click="delClick(scope.row.id)">Delete</el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      class="block pagePanel"
      :pageTotal="eventIssueTotal"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :pageSize="pageSize"
      :currentPage="currentPage"
    ></pagination>

    <!-- 删除提示框 -->
    <el-dialog title="Delete" :visible.sync="delDialogVisible" width="300px" append-to-body>
      <div class="del-dialog-cnt">Confirm to delete ?</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delDialogVisible = false">cancel</el-button>
        <el-button type="primary" @click="delEventIssue()">confirm</el-button>
      </span>
    </el-dialog>
    <!-- 添加 配置模块框 -->
    <el-dialog
      :visible.sync="dialogVisible"
      title="Event Issue"
      :close-on-click-modal="false"
      :append-to-body="true"
    >
      <el-form
        :model="eventIssue"
        label-position="left"
        label-width="95px"
        ref="eventIssueForm"
        :rules="rules"
      >
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="OfferId" prop="offerId">
              <el-input v-model="eventIssue.offerId" />
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <el-form-item label="EventName" prop="eventName">
              <el-input v-model="eventIssue.eventName" placeholder="eventName"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="status" prop="status">
              <el-select v-model="eventIssue.status" placeholder="请选择" style="width:200px;">
                <el-option
                  v-for="item in statusOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="changeEventIssue()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import Pagination from '@/components/pagination';

  import { getEventIssueList, updateEventIssue, deleteEventIssue } from 'api/affiliate/detail';
  const defaultEventIssue = {
    offerId: '',
    eventName: '',
    status: 0,
  };
  export default {
    components: {
      Pagination,
    },
    props: {
      affiliateId: {
        type: [String, Number],
        required: true,
      },
    },
    data() {
      return {
        filter: {},
        selectIds: [],
        eventIssueTotal: null,
        currentPage: 1,
        pageSize: 20,
        delDialogVisible: false,
        eventIssueList: [],
        rules: {
          eventName: [
            {
              required: true,
              message: 'eventName must be specified',
            },
          ],
          status: [
            {
              required: true,
              message: 'status must be select',
            },
          ],
        },
        dialogVisible: false,
        eventIssue: {},
        statusOptions: [
          {
            label: '关闭',
            value: 0,
          },
          {
            label: '开启',
            value: 1,
          },
        ],
      };
    },
    mounted() {
      this.getEventIssueList();
    },
    methods: {
      getEventIssueList(curPage) {
        if (curPage) {
          this.currentPage = curPage;
        }
        const param = {
          page: this.currentPage,
          pageSize: this.pageSize,
          affiliateId: this.affiliateId,
          ...this.filter,
        };
        getEventIssueList(param).then((response) => {
          if (response.code === 200) {
            this.eventIssueList = response.result;
            this.eventIssueTotal = response.total;
          }
        });
      },
      changeEventIssueClick(row) {
        this.eventIssue = Object.assign({}, defaultEventIssue, row);
        this.dialogVisible = true;
        this.$nextTick(() => {
          this.$refs.eventIssueForm.clearValidate();
        });
      },
      delEventIssue(id) {
        const ids = id ? id : this.selectIds.join(',');
        const params = {
          affiliateId: this.affiliateId,
          ids,
        };
        deleteEventIssue(params).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Delete Success',
              type: 'success',
            });
            this.getEventIssueList();
          } else {
            this.$message.error('Delete Error:' + response.message);
          }
        });
        this.delDialogVisible = false;
      },
      changeEventIssue() {
        this.$refs.eventIssueForm.validate((valid) => {
          if (valid) {
            let param = {
              ...this.eventIssue,
              affiliateId: this.affiliateId,
            };
            const typeStr = param.id ? 'Update' : 'Add';
            updateEventIssue(param).then((response) => {
              if (response.code === 200) {
                this.$message({
                  message: typeStr + ' Success',
                  type: 'success',
                });
                this.getEventIssueList();
              } else {
                this.$message.error(typeStr + ' Error:' + response.message);
              }
            });
            this.dialogVisible = false;
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      delClick(id) {
        this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.delEventIssue(id);
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            });
          });
      },
      batchDelClick() {
        if (this.selectIds.length <= 0) {
          this.$message.warning('你还没有选择要删除的数据哦~');
          return;
        }
        this.delDialogVisible = true;
      },
      handleSelectionChange(val) {
        this.selectIds = val.map((item) => item.id);
      },
      handleSizeChange(val) {
        this.pageSize = val;
        this.handleCurrentChange(1);
      },
      handleCurrentChange(val) {
        this.currentPage = val;
        this.getEventIssueList();
      },
    },
  };
</script>

<style></style>
