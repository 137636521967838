<template>
  <div>
    <el-row class="row_line">
      <el-form :inline="true">
        <el-col :lg="10" :md="20">
          <el-form-item
            label="OfferId(show last 30 records,if not found,pls Search)"
            label-width="350px"
          >
            <el-input v-model="filter.offerIds"></el-input>
          </el-form-item>
        </el-col>
        <el-col :lg="4" :md="20">
          <el-form-item label="SubAff" label-width="60px">
            <el-input v-model="filter.affSub"></el-input>
          </el-form-item>
        </el-col>
        <el-col :lg="2" :md="20">
          <el-form-item>
            <el-button type="primary" @click="searchOfferAffSubBlacklist(1)">Search</el-button>
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
    <el-row>
      <el-form :inline="true" :model="offerAffSubBlacklist" label-width="67px">
        <el-form-item label="OfferIds">
          <el-input v-model="offerAffSubBlacklist.offerIds"></el-input>
        </el-form-item>
        <el-form-item label="AffSub">
          <el-input v-model="offerAffSubBlacklist.affSub"></el-input>
        </el-form-item>

        <el-form-item label="Desc">
          <el-input v-model="offerAffSubBlacklist.description"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="addOfferAffSubBlack">Add</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="delBatchAdjustClick">Delete</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <el-table
      :data="blackSubAffOffersLists"
      @selection-change="handleSelectionChange"
      highlight-current-row
      border
      stripe
      style="width:100%;"
      height="80vmin"
    >
      <el-table-column type="selection" width="55" align="center"></el-table-column>
      <el-table-column
        label="OfferId"
        prop="offerId"
        min-width="60px"
        align="center"
      ></el-table-column>
      <el-table-column label="affSub" prop="affSub" align="center"></el-table-column>
      <el-table-column label="Api Sync" prop="apiSync" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.apiSync }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Desc" prop="description" align="center"></el-table-column>
      <el-table-column
        label="operator"
        prop="operateTime"
        min-width="100px"
        align="center"
      ></el-table-column>
    </el-table>
    <pagination
      class="block pagePanel"
      :pageTotal="total"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :pageSize="pageSize"
      :currentPage="currentPage"
    ></pagination>
    <el-dialog title="Delete" :visible.sync="delVisible" width="300px" append-to-body>
      <div class="del-dialog-cnt">Confirm to delete ?</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delVisible = false">cancel</el-button>
        <el-button type="primary" @click="delOfferAffSubBlacklist">confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {
    getOfferSubBlacklist,
    addOfferSubBlacklist,
    deleteOfferSubBlacklist,
  } from 'api/affiliate/detail';
  import Pagination from '@/components/pagination';
  export default {
    components: {
      Pagination,
    },
    props: {
      affiliateId: {
        type: [String, Number],
        required: true,
      },
    },
    data() {
      return {
        filter: {},
        offerAffSubBlacklist: {},
        blackSubAffOffersLists: [],
        selectionList: [],
        delArr: [],
        total: 0,
        delVisible: false,
        currentPage: 1,
        pageSize: 20,
      };
    },
    mounted() {
      this.searchOfferAffSubBlacklist();
    },
    methods: {
      searchOfferAffSubBlacklist(curPage) {
        if (curPage) {
          this.currentPage = curPage;
        }
        const param = {
          page: this.currentPage,
          pageSize: this.pageSize,
        };
        if (this.filter.offerIds) {
          param.offerIds = this.filter.offerIds;
        }
        if (this.filter.affSub) {
          param.affSub = this.filter.affSub;
        }
        getOfferSubBlacklist(this.affiliateId, param).then((response) => {
          if (response.code === 200) {
            this.blackSubAffOffersLists = response.result;
            this.total = response.total;
          }
        });
      },
      addOfferAffSubBlack() {
        const param = {
          ...this.offerAffSubBlacklist,
        };
        if (param.offerIds) {
          param.offerIds = param.offerIds.split(',');
        }
        addOfferSubBlacklist(this.affiliateId, param).then((response) => {
          if (response.code === 200) {
            this.$message.success('Add Success');
            this.searchOfferAffSubBlacklist();
            this.offerAffSubBlacklist = {};
          } else {
            this.$message.error(response.message);
          }
        });
      },
      delBatchAdjustClick() {
        const length = this.selectionList.length;
        if (length <= 0) {
          this.$message.warning('你还没有选择要删除的数据哦~');
          return;
        }
        this.delVisible = true;
        for (let i = 0; i < length; i++) {
          this.delArr.push(this.selectionList[i].id);
        }
      },
      delOfferAffSubBlacklist() {
        const ids = this.delArr.join(',');
        deleteOfferSubBlacklist(this.affiliateId, ids).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Delete Success',
              type: 'success',
            });
            this.searchOfferAffSubBlacklist();
          } else {
            this.$message.error('Delete Error:' + response.message);
          }
        });
        this.delVisible = false;
        this.delArr = [];
      },
      handleSelectionChange(val) {
        this.selectionList = val;
      },
      handleSizeChange(val) {
        this.pageSize = val;
        this.handleCurrentChange(1);
      },
      handleCurrentChange(val) {
        this.currentPage = val;
        this.searchOfferAffSubBlacklist();
      },
    },
  };
</script>

<style></style>
