<template>
  <div>
    <div class="account_item">
      <el-form>
        <el-col :lg="8" :md="20">
          <el-form-item label="Account Name:" label-width="150px">
            <el-input v-model="unameValue" placeholder="account name here ..."></el-input>
          </el-form-item>
        </el-col>
        <el-col :lg="8" :md="20" :offset="1">
          <el-form-item label="Password: " label-width="100px">
            <el-input v-model="passwordValue" placeholder="password here ..."></el-input>
          </el-form-item>
        </el-col>
        <el-col :lg="6" :md="20" :offset="1">
          <el-form-item>
            <el-button type="primary" @click="eventClick">Create Account</el-button>
          </el-form-item>
        </el-col>
      </el-form>
    </div>
    <el-table
      :data="affAccountList"
      border
      stripe
      highlight-current-row
      style="width: 100%"
      height="80vmin"
    >
      <el-table-column label="Account Name" prop="uname" align="center"></el-table-column>
      <el-table-column label="Creator" prop="createBy" align="center"></el-table-column>
      <el-table-column label="Create Time" prop="createTime" align="center"></el-table-column>
      <el-table-column label="Action" min-width="100" align="center">
        <template slot-scope="scope">
          <el-button type="primary" @click="updateAffClick(scope.row)">Update</el-button>
          <el-button type="primary" @click="delAffClick(scope.row)">Delete</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 删除提示框 -->
    <el-dialog title="Delete" :visible.sync="delAffVisible" width="300px" append-to-body>
      <div class="del-dialog-cnt">Confirm to delete ?</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delAffVisible = false">cancel</el-button>
        <el-button type="primary" @click="delaffAccountRow">confirm</el-button>
      </span>
    </el-dialog>
    <!-- 更新密码和密码 -->
    <el-dialog
      title="Update"
      width="40%"
      :visible.sync="updateAffVisible"
      :append-to-body="true"
      :close-on-click-modal="false"
    >
      <el-dialog width="30%" title="Tip" center :visible.sync="innerVisible" append-to-body>
        <span>Password And Email Confirm?</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="innerVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirmUpdatePasswordClick">确 定</el-button>
        </span>
      </el-dialog>
      <el-form label-position="left">
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="Password" label-width="80px">
              <el-input
                v-model="updatePasswordValue"
                placeholder="new password here ..."
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <el-form-item label="Email" label-width="60px">
              <el-input v-model="updateEmailValue" placeholder="new email here ..."></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="updateAffVisible = false">取 消</el-button>
        <el-button type="primary" @click="innerVisible = true">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {
    getAccountList,
    addAccount,
    updateAccount,
    deleteAccountList,
  } from 'api/affiliate/detail';
  export default {
    props: {
      affiliateId: {
        type: [String, Number],
        required: true,
      },
    },
    data() {
      return {
        affAccountName: null,
        resetPasswordValue: '',
        unameValue: '',
        passwordValue: '',
        affAccountList: [],
        delAffVisible: false,
        updateAffVisible: false,
        innerVisible: false,
        updatePasswordValue: '',
        updateEmailValue: null,
      };
    },
    mounted() {
      this.getAffAccountList();
    },
    methods: {
      eventClick() {
        const param = {
          uname: this.unameValue,
          passwd: this.passwordValue,
        };

        addAccount(this.affiliateId, param).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Update Success',
              type: 'success',
            });
            this.getAffAccountList();
          } else {
            this.$message.error('Update Error:' + response.message);
          }
        });
      },
      getAffAccountList() {
        getAccountList(this.affiliateId).then((response) => {
          if (response.code === 200) {
            this.affAccountList = response.result;
          } else {
            this.$message.error(response.message);
          }
        });
      },
      updateAffClick(row) {
        this.accountId = row.id;
        this.updatePasswordValue = row.passwd;
        this.updateEmailValue = row.email;
        this.updateAffVisible = true;
      },
      delAffClick(row) {
        this.accountId = row.id;
        this.delAffVisible = true;
      },
      delaffAccountRow() {
        deleteAccountList(this.affiliateId, this.accountId).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Delete Success',
              type: 'success',
            });
            this.delAffVisible = false;
            this.getAffAccountList();
          } else {
            this.$message.error('Delete Error' + response.status.message);
          }
        });
      },
      confirmUpdatePasswordClick() {
        const param = {
          id: this.accountId,
          passwd: this.updatePasswordValue,
          email: this.updateEmailValue,
        };
        updateAccount(this.affiliateId, param).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Update Success',
              type: 'success',
            });
            this.getAffAccountList();
            this.innerVisible = false;
            this.updateAffVisible = false;
            this.updatePasswordValue = '';
          } else {
            this.$message.error('Update Error:' + response.message);
          }
        });
      },
    },
  };
</script>

<style></style>
