<template>
  <div>
    <el-form :inline="true" :model="filter" style="margin-left:10px;">
      <el-form-item label="OfferId">
        <el-input v-model="filter.offerId"></el-input>
      </el-form-item>
      <el-form-item label="SourceId">
        <el-input v-model="filter.sourceId"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getAdjustRateList(1)">Search</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="openAddModal">Add</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="danger" @click="delBatchAdjustClick">Delete</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="adjustRatedataList"
      @selection-change="handleadjustRateSelectionChange"
      highlight-current-row
      border
      stripe
      style="width:100%;"
      height="80vmin"
    >
      <el-table-column type="selection" width="55" align="center" fixed></el-table-column>
      <el-table-column label="OfferId" prop="offerId" align="center"></el-table-column>
      <el-table-column
        label="OfferName"
        width="200"
        prop="offerName"
        align="center"
      ></el-table-column>
      <el-table-column label="AffiliateId" min-width="90" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.affiliateId }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="AffiliateSub"
        prop="affiliateSub"
        min-width="100"
        align="center"
      ></el-table-column>

      <el-table-column
        label="Prod"
        prop="prod"
        align="center"
        min-width="100"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="SourceId"
        prop="sourceId"
        min-width="100"
        align="center"
      ></el-table-column>
      <el-table-column label="Rate" prop="rate" min-width="80" align="center"></el-table-column>
      <el-table-column
        label="Description"
        prop="description"
        min-width="170px"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="CreateTime"
        prop="createTime"
        min-width="170"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="Action"
        prop="action"
        min-width="170"
        align="center"
        show-overflow-tooltip
      >
        <template v-slot="scope">
          <el-button size="small" type="primary" @click="openEditModal(scope.row)"
            >Update</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <pagination
      class="block pagePanel"
      :pageTotal="adjustRateListTotal"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :pageSize="pageSize"
      :currentPage="currentPage"
    ></pagination>
    <!-- 删除提示框 -->
    <el-dialog title="Delete" :visible.sync="deladjustRateVisible" width="300px" append-to-body>
      <div class="del-dialog-cnt">Confirm to delete ?</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deladjustRateVisible = false">cancel</el-button>
        <el-button type="primary" @click="delAdjustRateRow">confirm</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="isAddModal ? 'Add' : 'Update'" :visible.sync="formModalVisible">
      <el-form :inline="true" :model="adjustRates" label-width="90px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="OfferId">
              <el-input v-model="adjustRates.offerId" :disabled="!isAddModal"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="AffiliateSub">
              <el-input v-model="adjustRates.affiliateSub" :disabled="!isAddModal"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="Prod">
              <el-input v-model="adjustRates.prod" :disabled="!isAddModal"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="SourceId">
              <el-input v-model="adjustRates.sourceId" :disabled="!isAddModal"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="Rate">
              <el-input v-model="adjustRates.rate"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="Description">
              <el-input v-model="adjustRates.description" :disabled="!isAddModal"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="formModalVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveAdjustRate">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { getAdjustRateList, saveAdjustRate, deleteAdjustRate } from 'api/affiliate/detail';

  import Pagination from '@/components/pagination';

  const defaultAdjustRate = {
    affiliateSub: '',
    offerId: '',
    prod: '',
    sourceId: '',
    rate: '',
    description: '',
  };
  export default {
    components: {
      Pagination,
    },
    props: {
      affiliateId: {
        type: [String, Number],
        required: true,
      },
    },
    data() {
      return {
        adjustRates: {
          affiliateSub: '',
          offerId: '',
          prod: '',
          sourceId: '',
          rate: '',
          description: '',
        },
        adjustRatedataList: [],
        deladjustRateVisible: false,
        adjustRateListTotal: 0,
        currentPage: 1,
        pageSize: 20,
        filter: {
          offerId: '',
          sourceId: '',
        },
        adjustRateSelectionList: [],
        delArr: [],
        isAddModal: true,
        formModalVisible: false,
      };
    },
    mounted() {
      this.getAdjustRateList();
    },
    methods: {
      getAdjustRateList(page) {
        if (page) {
          this.currentPage = page;
        }
        let param = {
          page: this.currentPage,
          pageSize: this.pageSize,
          ...this.filter,
        };
        getAdjustRateList(this.affiliateId, param).then((response) => {
          if (response.code === 200) {
            this.adjustRatedataList = response.result;
            this.adjustRateListTotal = response.total;
          }
        });
      },
      saveAdjustRate() {
        const param = {
          ...this.adjustRates,
        };
        saveAdjustRate(this.affiliateId, param).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: `${this.isAddModal ? 'Add' : 'Update'} Success}`,
              type: 'success',
            });
            this.getAdjustRateList();
            this.formModalVisible = false;
          } else {
            this.$message.error(`${this.isAddModal ? 'Add' : 'Update'}Error:` + response.message);
          }
        });
      },
      delBatchAdjustClick() {
        const length = this.adjustRateSelectionList.length;
        if (length <= 0) {
          this.$message.warning('你还没有选择要删除的数据哦~');
          return;
        }
        this.deladjustRateVisible = true;
        for (let i = 0; i < length; i++) {
          this.delArr.push(this.adjustRateSelectionList[i].id);
        }
      },
      delAdjustRateRow() {
        const ids = this.delArr.join(',');
        deleteAdjustRate(this.affiliateId, ids).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Delete Success',
              type: 'success',
            });
            this.getAdjustRateList();
          } else {
            this.$message.error('Delete Error:' + response.message);
          }
        });
        this.deladjustRateVisible = false;
        this.delArr = [];
      },
      handleadjustRateSelectionChange(val) {
        this.adjustRateSelectionList = val;
      },
      handleSizeChange(val) {
        this.pageSize = val;
        this.handleCurrentChange(1);
      },
      handleCurrentChange(val) {
        this.currentPage = val;
        this.getAdjustRateList();
      },
      openAddModal() {
        this.adjustRates = {
          ...defaultAdjustRate,
        };
        this.isAddModal = true;
        this.formModalVisible = true;
      },
      openEditModal(row) {
        this.adjustRates = {
          ...defaultAdjustRate,
          ...row,
        };
        this.isAddModal = false;
        this.formModalVisible = true;
      },
    },
  };
</script>

<style></style>
