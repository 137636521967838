<template>
  <div>
    <div class="button_group">
      <el-form :inline="true" :model="filter">
        <el-form-item label="OfferId:" label-width="70px">
          <el-input v-model="filter.offerId" placeholder="offerId here"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getPriorityList(1)">Search</el-button>
          <el-button type="primary" @click="delPrioritys">Delete</el-button>
          <!-- addVisible= true -->
          <el-button type="primary" @click="addPriorityClick">Add</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="priorityList"
      border
      style="width:100%;"
      height="80vmin"
      @selection-change="handleSelectionChange"
      @select-all="handleSelectionChange"
      :row-class-name="tableRowClassName"
      highlight-current-row
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column label="Offer Id" min-width="100" align="center">
        <template slot-scope="scope">
          <span class="cor337ab7" @click="offerdetailClick(scope.row)">{{
            scope.row.offerId
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="Description"
        prop="description"
        min-width="200"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="Operator"
        prop="createBy"
        min-width="100"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="Operate Time"
        prop="createTime"
        min-width="170"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column label="Option" align="center" min-width="180">
        <template slot-scope="scope">
          <el-button type="primary" @click="updatePriorityClick(scope.row)" size="small"
            >Update</el-button
          >
          <el-button type="danger" @click="delRowClick(scope.row)" size="small">Delete</el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      class="block pagePanel"
      :pageTotal="total"
      @handleSizeChange="handlePrioritySizeChange"
      @handleCurrentChange="handlePriorityCurrentChange"
      :pageSize="pageSize"
      :currentPage="currentPage"
    ></pagination>

    <el-dialog :title="`${priority.id ? 'Update' : 'Add'} Priority`" :visible.sync="addVisible">
      <el-form
        ref="addPriority"
        :model="priority"
        label-position="left"
        label-width="110px"
        :rules="rules"
      >
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="OfferId" prop="offerId">
              <el-input v-model="priority.offerId"></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <el-form-item label="Description" prop="description">
              <el-input v-model="priority.description"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { getPriorityList, changePrioriy, deletePriority } from 'api/affiliate/detail';

  import Pagination from '@/components/pagination';
  import { filterObject } from 'utils/utils';
  import { myMixin } from '@/mixins/mixins.js';

  const defaultPriority = {
    affiliateId: '',
    offerId: '',
    description: '',
  };
  export default {
    mixins: [myMixin],
    components: {
      Pagination,
    },
    props: ['affiliateId'],
    data() {
      return {
        filter: {},
        offerWhiteSelectionList: [],
        priorityList: [],
        total: 0,
        currentPage: 1,
        pageSize: 20,
        addVisible: false,
        priority: {},
        priorityListSelectedList: [],
        rules: {
          offerId: [
            {
              required: true,
              message: 'please write offerId',
              trigger: 'blur',
            },
          ],
        },
      };
    },
    methods: {
      getPriorityList(page) {
        if (page) {
          this.currentPage = page;
        }
        const param = filterObject({
          ...this.filter,
          page: this.currentPage,
          pageSize: this.pageSize,
          affiliateId: this.affiliateId,
        });

        getPriorityList(param).then((response) => {
          if (response.code === 200) {
            this.priorityList = response.result;
            this.total = response.total;
            this.pageSize = response.pageSize;
            this.currentPage = response.currentPage;
          } else {
            this.$message.error(response.message);
          }
        });
      },
      addPriorityClick() {
        this.pri = Object.assign({}, defaultPriority);
        this.addVisible = true;
      },
      updatePriorityClick(row) {
        this.priority = JSON.parse(JSON.stringify(row));
        this.addVisible = true;
      },
      handleOfferWhiteSelectionChange(val) {
        this.offerWhiteSelectionList = val.map((item) => item.offerId);
      },
      tableRowClassName(row) {
        if (row.row.offerStatus == 'hold') {
          return 'gray-row';
        } else {
          return '';
        }
      },
      submitForm() {
        this.$refs.addPriority.validate((valid) => {
          if (valid) {
            const param = {
              ...this.priority,
              affiliateId: this.affiliateId,
            };
            changePrioriy(param).then((response) => {
              if (response.code === 200) {
                this.$message({
                  message: `${param.id ? 'Update' : 'Add'} success`,
                  type: 'success',
                });
                this.getPriorityList();
                this.addVisible = false;
              } else {
                this.$message.error('Add Error:' + response.message);
              }
            });
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      handlePrioritySizeChange(val) {
        this.pageSize = val;
        this.handlePriorityCurrentChange(1);
      },
      handlePriorityCurrentChange(val) {
        this.currentPage = val;
        this.getPriorityList();
      },
      delRowClick(row) {
        this.$confirm('Are you want to delete ?', 'Tip', {
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
        })
          .then(() => {
            const id = row.id;
            this.deletePriority(id);
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            });
          });
      },
      delPrioritys() {
        const length = this.priorityListSelectedList.length;
        if (length <= 0) {
          this.$message.warning('请先选择要删除的数据~');
          return;
        }
        this.$confirm('Are you want to delete ?', 'Tip', {
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
        })
          .then(() => {
            const ids = this.priorityListSelectedList.map((item) => item.id)?.join();
            this.deletePriority(ids);
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            });
          });
      },
      deletePriority(priorityIds) {
        deletePriority({
          affiliateId: this.affiliateId,
          operationIds: priorityIds,
        }).then((response) => {
          if (response.code == 200) {
            this.$message({
              message: 'Delete Success',
              type: 'success',
            });
            this.getPriorityList();
          } else {
            this.$message.error('Delete Error:' + response.message);
          }
        });
      },
      handleSelectionChange(val) {
        this.priorityListSelectedList = val;
      },
      affiliatedetailClick(row) {
        const { href } = this.$router.resolve({
          path: '/affiliate/detail',
          query: {
            affiliateId: row.affiliateId,
          },
        });
        window.open(href, '_blank');
      },
      offerdetailClick(row) {
        const { href } = this.$router.resolve({
          path: '/offer/detail',
          query: {
            offerId: row.offerId,
          },
        });
        window.open(href, '_blank');
      },
    },
  };
</script>

<style lang="scss">
  .el-table .gray-row {
    background-color: gray !important;
  }
  .err-tip .el-message-box__message {
    color: red;
    font-weight: bolder;
  }
</style>
