<template>
  <div>
    <el-form :inline="true" :model="adjustRates" style="margin-left:10px;">
      <el-form-item label="OfferId">
        <el-input v-model="adjustRates.offerId" style="width:120px;"></el-input>
      </el-form-item>
      <el-form-item label="AffiliateSub">
        <el-input v-model="adjustRates.affiliateSub" style="width:100px;"></el-input>
      </el-form-item>
      <el-form-item label="SourceId">
        <el-input v-model="adjustRates.sourceId" style="width:100px;"></el-input>
      </el-form-item>
      <el-form-item label="Interval">
        <el-popover
          style="max-height: 500px;overflow-y: auto"
          placement="bottom"
          width="340"
          trigger="click"
        >
          <el-button type="primary" size="mini" @click="addInterval">add interval</el-button>
          <div style="max-height: 260px;overflow-y: auto">
            <template v-for="(item, index) in adjustRates.interval">
              <p :key="index" style="margin: 8px 0">
                <el-input-number
                  disabled
                  style="width: 80px"
                  size="mini"
                  placeholder="请输入内容"
                  :controls="false"
                  v-model="adjustRates.interval[index].start"
                >
                </el-input-number>
                <span>~</span>
                <el-input-number
                  :disabled="adjustRates.interval.length !== index + 1"
                  style="width: 80px"
                  :min="adjustRates.interval[index].start + 1"
                  size="mini"
                  placeholder="请输入内容"
                  :controls="false"
                  v-model="adjustRates.interval[index].end"
                >
                </el-input-number>
                <span> rate: </span>
                <el-input-number
                  style="width: 80px"
                  :min="0"
                  size="mini"
                  placeholder="请输入内容"
                  :controls="false"
                  v-model="adjustRates.interval[index].rate"
                ></el-input-number>
                <i
                  class="el-icon-delete"
                  style="margin-left: 8px"
                  @click="deleteInterval(index)"
                ></i>
              </p>
            </template>
          </div>
          <el-button slot="reference">Set Interval</el-button>
        </el-popover>
      </el-form-item>
      <el-form-item label="Description">
        <el-input v-model="adjustRates.description" style="width:100px;"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="addAdjustClick">Add</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="delBatchAdjustClick">Delete</el-button>
      </el-form-item>
    </el-form>
    <el-form :inline="true" :model="filter" style="margin-left:10px;">
      <el-form-item label="OfferId">
        <el-input v-model="filter.offerId" style="width:120px;"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getIntervalScaleAdjust(1)">Search</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="adjustRatedataList"
      @selection-change="handleadjustRateSelectionChange"
      highlight-current-row
      border
      stripe
      style="width:100%;"
      height="80vmin"
    >
      <el-table-column type="selection" width="55" align="center" fixed></el-table-column>
      <el-table-column label="OfferId" prop="offerId" align="center"></el-table-column>
      <el-table-column
        label="OfferName"
        width="200"
        prop="offerName"
        align="center"
      ></el-table-column>
      <el-table-column label="AffiliateId" min-width="90" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.affiliateId }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="AffiliateSub"
        prop="affiliateSub"
        min-width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        label="SourceId"
        prop="sourceId"
        min-width="100"
        align="center"
      ></el-table-column>

      <el-table-column label="Rate" prop="Rate" align="center" min-width="100">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" placement="top">
            <div slot="content">
              <div v-for="(item, i) in scope.row.packageParamList" :key="i">
                <p>
                  <span>{{ item.minConvert }}</span
                  >~<span>{{ item.maxConvert }}</span> rate: <span>{{ item.rate }}</span>
                </p>
              </div>
            </div>
            <span style="color: #409eff">MouseDisplay</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        label="Description"
        prop="description"
        min-width="170px"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="CreateTime"
        prop="createTime"
        min-width="170"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
    </el-table>
    <pagination
      class="block pagePanel"
      :pageTotal="adjustRateListTotal"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :pageSize="pageSize"
      :currentPage="currentPage"
    ></pagination>
    <!-- 删除提示框 -->
    <el-dialog title="Delete" :visible.sync="deladjustRateVisible" width="300px" append-to-body>
      <div class="del-dialog-cnt">Confirm to delete ?</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deladjustRateVisible = false">cancel</el-button>
        <el-button type="primary" @click="delAdjustRateRow">confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { getIntervalScaleAdjust, addMoreCovert, deleteMoreCovert } from 'api/affiliate/detail';

  import Pagination from '@/components/pagination';
  export default {
    components: {
      Pagination,
    },
    props: {
      affiliateId: {
        type: [String, Number],
        required: true,
      },
    },
    data() {
      return {
        adjustRates: {
          affiliateSub: null,
          offerId: null,
          sourceId: null,
          description: null,
          interval: [],
        },
        adjustRatedataList: [],
        deladjustRateVisible: false,
        adjustRateListTotal: 0,
        currentPage: 1,
        pageSize: 20,
        adjustRateSelectionList: [],
        delArr: [],
        filter: {},
      };
    },
    mounted() {
      this.getIntervalScaleAdjust();
    },
    methods: {
      getIntervalScaleAdjust(current) {
        if (current) {
          this.currentPage = current;
        }
        let param = {
          page: this.currentPage,
          pageSize: this.pageSize,
          ...this.filter,
        };
        getIntervalScaleAdjust(this.affiliateId, param).then((response) => {
          if (response.code === 200) {
            this.adjustRatedataList = response.result.data;
            this.adjustRateListTotal = response.result.total;
          }
        });
      },
      addAdjustClick() {
        if (this.adjustRates.interval.length === 0) {
          this.$message.error('Please add at least one interval');
          return;
        }
        let minConvertList = [];
        let maxConvertList = [];
        let rateList = [];
        for (const paramElement of this.adjustRates.interval) {
          minConvertList.push(paramElement.start);
          maxConvertList.push(paramElement.end);
          rateList.push(paramElement.rate);
        }
        const param = {
          ...this.adjustRates,
          minConvertList,
          maxConvertList,
          rateList,
          affiliateId: Number(this.affiliateId),
        };
        addMoreCovert(this.affiliateId, param).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Add Success',
              type: 'success',
            });
            this.getIntervalScaleAdjust();
            this.adjustRates = {
              affiliateSub: null,
              offerId: null,
              sourceId: null,
              description: null,
              interval: [],
            };
          } else {
            this.$message.error('Add Error:' + response.message);
          }
        });
      },
      delBatchAdjustClick() {
        const length = this.adjustRateSelectionList.length;
        if (length <= 0) {
          this.$message.warning('你还没有选择要删除的数据哦~');
          return;
        }
        this.deladjustRateVisible = true;
        for (let i = 0; i < length; i++) {
          for (const lengthElement of this.adjustRateSelectionList[i].packageParamList) {
            this.delArr.push(Number(lengthElement.id));
          }
        }
      },
      delAdjustRateRow() {
        deleteMoreCovert(this.affiliateId, this.delArr).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Delete Success',
              type: 'success',
            });
            this.getIntervalScaleAdjust();
          } else {
            this.$message.error('Delete Error:' + response.message);
          }
        });
        this.deladjustRateVisible = false;
        this.delArr = [];
      },
      handleadjustRateSelectionChange(val) {
        this.adjustRateSelectionList = val;
      },
      handleSizeChange(val) {
        this.pageSize = val;
        this.handleCurrentChange(1);
      },
      handleCurrentChange(val) {
        this.currentPage = val;
        this.getIntervalScaleAdjust();
      },
      addInterval() {
        if (this.adjustRates.interval.length > 0) {
          this.adjustRates.interval.push({
            start: this.adjustRates.interval[this.adjustRates.interval.length - 1].end + 1,
            end: this.adjustRates.interval[this.adjustRates.interval.length - 1].end + 2,
            rate: 0,
          });
        } else {
          this.adjustRates.interval.push({
            start: 0,
            end: 10,
            rate: 0,
          });
        }
      },
      deleteInterval(index) {
        if (
          this.adjustRates.interval.length > 1 &&
          this.adjustRates.interval.length - 1 !== index
        ) {
          if (index === 0) {
            this.adjustRates.interval[index + 1].start = 0;
          } else {
            this.adjustRates.interval[index + 1].start =
              this.adjustRates.interval[index - 1].end + 1;
          }
        }
        this.adjustRates.interval.splice(index, 1);
      },
    },
  };
</script>

<style></style>
