<template>
  <div class="flow-tag-container">
    <el-row>
      <el-form :inline="true" :model="flowInfo" style="margin-left:6px;">
        <el-form-item label="AffSubs">
          <el-input v-model="flowInfo.affSubs"></el-input>
        </el-form-item>
        <el-form-item label="Tag">
          <el-select
            v-model="flowInfo.tag"
            filterable
            allow-create
            default-first-option
            placeholder="请选择或输入Tag"
          >
            <el-option
              v-for="item in tagOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Description">
          <el-input v-model="flowInfo.description"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="addFlowTag">Add</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <el-row>
      <el-form inline :model="searchInfo" style="margin-left:6px;">
        <el-form-item label="AffSubs">
          <el-input v-model="searchInfo.affSubs"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchFlowTagList(1)">Search</el-button>
          <el-button type="primary" @click="batchDelFlowTag">Delete</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <el-table
      :data="sourceDataList"
      @selection-change="handleSelectionChange"
      border
      stripe
      style="width:100%;"
      height="80vmin"
      highlight-current-row
    >
      <el-table-column type="selection" width="55" align="center"></el-table-column>
      <el-table-column label="AffiliateId" prop="affiliateId"></el-table-column>
      <el-table-column label="Aff Sub" prop="affSub"></el-table-column>
      <el-table-column label="Tag" prop="tag"></el-table-column>
      <el-table-column label="Description" prop="description"></el-table-column>
      <el-table-column label="Date" prop="createTime"></el-table-column>
    </el-table>
    <pagination
      class="block pagePanel"
      :pageTotal="total"
      @handleSizeChange="handledetailSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :pageSize="pageSize"
      :currentPage="currentPage"
    ></pagination>
  </div>
</template>
<script>
  import Pagination from '@/components/pagination';
  import { getFlowTagList, addFlowTag, deleteFlowTag } from '@/api/affiliate/detail';
  import { getAllTags } from '@/api/affiliate/tag';
  export default {
    components: {
      Pagination,
    },
    props: {
      affiliateId: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        sourceDataList: [],
        flowInfo: {},
        searchInfo: {},
        delArr: [],
        total: 0,
        pageSize: 10,
        currentPage: 1,
        tagOption: [],
      };
    },
    mounted() {
      this.searchFlowTagList();
      this.getTagList();
    },
    methods: {
      searchFlowTagList(curPage) {
        if (curPage) {
          this.currentPage = curPage;
        }
        const param = {
          affiliateIds: this.affiliateId,
          affSubs: this.searchInfo.affSubs,
          page: this.currentPage,
          pageSize: this.pageSize,
        };

        getFlowTagList(param).then((resp) => {
          if (resp.code === 200) {
            this.sourceDataList = resp.result;
            this.total = resp.total;
          } else {
            this.sourceDataList = [];
            this.total = 0;
          }
        });
      },
      addFlowTag() {
        const param = {
          ...this.flowInfo,
          affiliateId: this.affiliateId,
        };
        if (this.flowInfo.affSubs) {
          param.affSubs = this.flowInfo.affSubs.split(',');
        }
        addFlowTag(param).then((resp) => {
          if (resp.code === 200) {
            this.$message.success('添加流量标签成功');
            this.searchFlowTagList(1);
          } else {
            this.$message.warning(resp.message);
          }
        });
      },
      batchDelFlowTag() {
        if (this.delArr.length === 0) {
          this.$message.warning('你还没选择要删除的数据');
          return;
        }
        this.$confirm('数据删除后将无法恢复，确定删除吗？', '提示').then(() => {
          const param = {
            affiliateId: this.affiliateId,
            trafficTagIds: [...this.delArr],
          };
          deleteFlowTag(param).then((resp) => {
            if (resp.code === 200) {
              this.$message.success('删除数据成功');
              this.searchFlowTagList(1);
              this.delArr = [];
            } else {
              this.$message.error('删除删除失败：' + resp.message);
            }
          });
        });
      },
      handleSelectionChange(val) {
        this.delArr = val.map((item) => item.id);
      },
      handledetailSizeChange(val) {
        this.pageSize = val;
        this.handledetailAffSubTagsCurrentChange(1);
      },
      handleCurrentChange(val) {
        this.currentPage = val;
        this.searchFlowTagList();
      },
      getTagList() {
        getAllTags()
          .then((resp) => {
            if (resp.code === 200) {
              this.tagOption = resp.result?.map((item) => ({
                label: item.trafficTag,
                value: item.trafficTag,
              }));
            } else {
              this.tagOption = [];
            }
          })
          .catch(() => {
            this.tagOption = [];
          });
      },
    },
  };
</script>
