<template>
  <div>
    <el-form :inline="true" :model="offerSubs" label-position="left" label-width="60px">
      <el-form-item label="OfferIds">
        <el-input v-model="offerSubs.offerIds"></el-input>
      </el-form-item>
      <el-form-item label="AffSub" style="margin-left:10px;">
        <el-input v-model="offerSubs.affSub"></el-input>
      </el-form-item>
      <el-form-item label="DailyCap" label-width="80px">
        <el-input v-model="offerSubs.dailyCap"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button class="add" type="primary" @click="addOfferSubClick">Add</el-button>
        <el-button type="primary" @click="delOfferSubClick">Delete</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="offerSubList"
      highlight-current-row
      @selection-change="handleOfferSubSelectionChange"
      border
      stripe
      style="width:100%;"
      height="80vmin"
    >
      <el-table-column type="selection" width="55" align="center"></el-table-column>
      <el-table-column label="offerId" prop="offerId" width="90" align="center">
        <template slot-scope="scope">
          <span class="cor337ab7" @click="toOfferDetailClick(scope.row)">{{
            scope.row.offerId
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="offerName" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span class="cor337ab7" @click="toOfferDetailClick(scope.row)">{{
            scope.row.offerName
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="affSub" prop="affSub" width="90" align="center"></el-table-column>
      <el-table-column label="Status" prop="status" width="90" align="center"></el-table-column>
      <el-table-column
        label="DailyCap"
        prop="dailyCap"
        width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        label="Operator"
        prop="operator"
        width="130"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="Operate Time"
        prop="operateTime"
        width="170"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column label="Action" width="110" align="center">
        <template slot-scope="scope">
          <el-button type="primary" @click="updateClick(scope.row)">Update</el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      class="block pagePanel"
      :pageTotal="offerSubTotal"
      @handleSizeChange="handleOfferSubSizeChange"
      @handleCurrentChange="handleOfferSubCurrentChange"
      :pageSize="pageSize"
      :currentPage="currentPage"
    ></pagination>
    <!-- 删除提示框 -->
    <el-dialog title="Delete" :visible.sync="delOfferSubVisible" width="300px" append-to-body>
      <div class="del-dialog-cnt">Confirm to delete ?</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delOfferSubVisible = false">cancel</el-button>
        <el-button type="primary" @click="delOfferSubRow">confirm</el-button>
      </span>
    </el-dialog>
    <el-dialog title="Update offer cap" :visible.sync="updateOfferSubVisible" append-to-body>
      <el-form :model="offerSubCap" label-position="left" label-width="80px">
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="Offer Id" prop="offerId">
              <el-input v-model="offerSubCap.offerId" placeholder="Offer Id" />
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <el-form-item label="AffSub" prop="affSub">
              <el-input v-model="offerSubCap.affSub" placeholder="affSub"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="Daily Cap" prop="dailyCap">
              <el-input v-model="offerSubCap.dailyCap" placeholder="dailyCap"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="updateOfferSubVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateOfferSubCap">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import Pagination from '@/components/pagination';
  import { cloneDeep } from 'lodash-es';
  import {
    getOfferSubCapList,
    addOfferSubCap,
    updateOfferSubCap,
    deleteOfferSubCap,
  } from 'api/affiliate/detail';
  let clickTimer = null;
  const defaultOfferSubCap = {
    offerId: '',
    affSub: '',
    dailyCap: '',
  };
  export default {
    components: {
      Pagination,
    },
    props: {
      affiliateId: {
        type: [String, Number],
        required: true,
      },
    },
    data() {
      return {
        offerSubs: {},
        offerSubList: [],
        offerSubTotal: null,
        currentPage: 1,
        pageSize: 20,
        delOfferSubVisible: false,
        offerSubCap: Object.assign({}, defaultOfferSubCap),
        updateOfferSubVisible: false,
        offerSubSelectionList: [],
        delArr: [],
      };
    },
    mounted() {
      this.getAffSubCapList();
    },
    methods: {
      getAffSubCapList() {
        let param = {
          page: this.currentPage,
          pageSize: this.pageSize,
        };
        getOfferSubCapList(this.affiliateId, param).then((response) => {
          if (response.code === 200) {
            this.offerSubList = response.result;
            this.offerSubTotal = response.total;
          } else {
            this.$message.error(response.message);
          }
        });
      },
      addOfferSubClick() {
        const param = { ...this.offerSubs };
        if (param.offerIds) {
          param.offerIds = param.offerIds.split(',');
        }
        addOfferSubCap(this.affiliateId, param).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Add Success',
              type: 'success',
            });
            this.getAffSubCapList();
          } else {
            this.$message.error('Add Error:' + response.message);
          }
        });
      },
      delOfferSubClick() {
        const length = this.offerSubSelectionList.length;
        if (length <= 0) {
          this.$message.warning('你还没有选择要删除的数据哦~');
          return;
        }
        this.delOfferSubVisible = true;
        for (let i = 0; i < length; i++) {
          this.delArr.push(this.offerSubSelectionList[i].id);
        }
      },
      delOfferSubRow() {
        const ids = this.delArr.join(',');
        deleteOfferSubCap(this.affiliateId, ids).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Delete Success',
              type: 'success',
            });
            this.getAffSubCapList();
          } else {
            this.$message.error('Delete Error:' + response.message);
          }
        });
        this.delOfferSubVisible = false;
        this.delArr = [];
      },
      updateClick(row) {
        this.offerSubCap = cloneDeep(row);
        this.updateOfferSubVisible = true;
      },
      updateOfferSubCap() {
        const param = {
          ...this.offerSubCap,
        };
        updateOfferSubCap(this.affiliateId, param).then((response) => {
          if (response.code === 200) {
            this.$message.success('Update Success');
            this.updateOfferSubVisible = false;
            this.getAffSubCapList();
          } else {
            this.message.error(response.message);
          }
        });
      },
      toOfferDetailClick(row) {
        if (clickTimer) {
          window.clearTimeout(clickTimer);
          clickTimer = null;
        }

        clickTimer = window.setTimeout(() => {
          this.$nextTick(() => {
            this.$router.push({
              path: '/offer/detail',
              query: {
                offerId: row.offerId,
              },
            });
          });
        }, 300);
      },
      handleOfferSubSelectionChange(val) {
        this.offerSubSelectionList = val;
      },
      handleOfferSubSizeChange(val) {
        this.pageSize = val;
        this.handleOfferSubCurrentChange(1);
      },
      handleOfferSubCurrentChange(val) {
        this.currentPage = val;
        this.getAffSubCapList();
      },
    },
  };
</script>

<style></style>
