<template>
  <div class="affiliate_detail">
    <div class="title" style="height: 54px;">
      <el-row :gutter="24">
        <el-col :span="16" style="margin-top:12px;">
          <h1 class="header">
            ID:
            <span>{{ affiliateId }}</span>
            -
            <span>{{ affiliateName }}</span>
            Status:
            <span>{{ status }}</span>
          </h1>
        </el-col>
        <el-col :span="3">
          <el-button type="primary" :disabled="havingOfferDisabled" @click="havingOffersClick"
            >Having Offers</el-button
          >
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="havingOfferDetailClick">Having Offer Detail</el-button>
        </el-col>
      </el-row>
    </div>
    <el-card>
      <el-tabs type="border-card" v-model="activeName" lazy>
        <el-tab-pane label="Top Offers & GEOs" name="first" style="margin-left:20px;">
          <top-offer :affiliateId="affiliateId" />
        </el-tab-pane>
        <el-tab-pane label="Account Access Aff Console" name="second" lazy>
          <account-access-aff :affiliateId="affiliateId" />
        </el-tab-pane>
        <el-tab-pane label="Source Whitelist Access" name="third" lazy>
          <source-white-list :affiliateId="affiliateId" />
        </el-tab-pane>
        <el-tab-pane label="Offer Whitelist Access" name="fourth" lazy>
          <offer-white-list-access :affiliateId="affiliateId" />
        </el-tab-pane>
        <el-tab-pane label="AffSub WhiteList Message" name="fifth" lazy>
          <aff-sub-white-list :affiliateId="affiliateId" />
        </el-tab-pane>
        <!--子渠道offer设cap-->
        <el-tab-pane label="Offer Aff Sub Cap Setting" name="sixth" lazy>
          <offer-aff-sub-cap :affiliateId="affiliateId" />
        </el-tab-pane>
        <el-tab-pane label="Tags Whitelist Access" name="seventh" lazy>
          <tags-whitelist :affiliateId="affiliateId" />
        </el-tab-pane>
        <el-tab-pane label="Priority Offer Whitelist" name="twenty" lazy>
          <priority-offer-whitelist :affiliateId="affiliateId" />
        </el-tab-pane>
        <el-tab-pane label="Payout Setting" name="eighth" lazy>
          <payout-setting :affiliateId="affiliateId" />
        </el-tab-pane>
        <el-tab-pane label="Adjust Rate Setting" name="ninth" lazy>
          <adjust-rate-setting :affiliateId="affiliateId" />
        </el-tab-pane>
        <el-tab-pane label="Interval Scale Adjust" name="interval" lazy>
          <interval-scale-adjust :affiliateId="affiliateId" />
        </el-tab-pane>
        <el-tab-pane label="Source Blacklist Access" name="tenth" lazy>
          <source-blacklist :affiliateId="affiliateId" />
        </el-tab-pane>
        <el-tab-pane label="Prod Blacklist Access" name="twelfth" lazy>
          <prod-blacklist :affiliateId="affiliateId" />
        </el-tab-pane>
        <el-tab-pane label="Offer Blacklist Access" name="thirteenth" lazy>
          <offer-blacklist :affiliateId="affiliateId" />
        </el-tab-pane>
        <el-tab-pane label="Offer Sub Aff Blacklist Access" name="fifteenth" lazy>
          <offer-aff-sub-blacklist :affiliateId="affiliateId" />
        </el-tab-pane>
        <el-tab-pane label="Aff Sub Source Black Message" name="seventeenth" lazy>
          <aff-sub-source-blacklist :affiliateId="affiliateId" />
        </el-tab-pane>
        <el-tab-pane label="Affiliate & Aff Sub Tags" name="eighteen" lazy>
          <affiliate2-aff-sub-tags :affiliateId="affiliateId" />
        </el-tab-pane>
        <el-tab-pane label="Event Payout" name="nineteen" lazy>
          <event-payout :affiliateId="affiliateId" />
        </el-tab-pane>
        <el-tab-pane label="Event Issue" name="twentyOne" lazy>
          <event-issue :affiliateId="affiliateId" />
        </el-tab-pane>
        <el-tab-pane label="Reject Issue" name="rejectIssue" lazy>
          <reject-issue :affiliateId="affiliateId" />
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>
<script>
  import { getOfferDistribution } from '@/api/affiliate/detail';
  import TopOffer from './components/TopOffer';
  import AccountAccessAff from './components/AccountAccessAff';
  import SourceWhiteList from './components/SourceWhiteList';
  import OfferWhiteListAccess from './components/OfferWhiteListAccess';
  import AffSubWhiteList from './components/AffSubWhiteList';
  import OfferAffSubCap from './components/OfferAffSubCap';
  import TagsWhitelist from './components/TagsWhitelist';
  import AdjustRateSetting from './components/AdjustRateSetting';
  import SourceBlacklist from './components/SourceBlacklist';
  import ProdBlacklist from './components/ProdBlacklist';
  import OfferBlacklist from './components/OfferBlacklist';
  import OfferAffSubBlacklist from './components/OfferAffSubBlacklist';
  import AffSubSourceBlacklist from './components/AffSubSourceBlacklist';
  import PayoutSetting from './components/PayoutSetting.vue';
  import Affiliate2AffSubTags from './components/Affiliate2AffSubTags';
  import IntervalScaleAdjust from './components/IntervalScaleAdjust';
  import EventPayout from './components/EventPayout.vue';
  import EventIssue from './components/EventIssue.vue';
  import RejectIssue from './components/RejectIssue.vue';
  import PriorityOfferWhitelist from './components/PriorityOfferWhitelist.vue';

  export default {
    components: {
      TopOffer,
      AccountAccessAff,
      SourceWhiteList,
      OfferWhiteListAccess,
      AffSubWhiteList,
      OfferAffSubCap,
      TagsWhitelist,
      AdjustRateSetting,
      SourceBlacklist,
      ProdBlacklist,
      OfferBlacklist,
      OfferAffSubBlacklist,
      AffSubSourceBlacklist,
      PayoutSetting,
      Affiliate2AffSubTags,
      IntervalScaleAdjust,
      EventPayout,
      EventIssue,
      RejectIssue,
      PriorityOfferWhitelist,
    },
    data() {
      return {
        sourceId: '',
        collsourceUploadFlag: false,
        addSourceVisible: false,
        highDescription: null,
        affiliateId: this.$route.query.affiliateId,
        affiliateName: this.$route.query.affiliateName,
        status: this.$route.query.status,
        havingOfferDisabled: false,
        activeName: 'first',
      };
    },
    methods: {
      havingOffersClick() {
        let sourceofferCount = '';
        this.havingOfferDisabled = true;
        const loadingMessage = this.$message({
          message: '加载中，请稍后...',
          type: 'warning',
        });
        getOfferDistribution(this.affiliateId)
          .then((response) => {
            if (response.code === 200) {
              const result = response.result || [];
              result.forEach((item) => {
                sourceofferCount += item.sourceId + ': ' + item.count;
                sourceofferCount += '</br>';
                this.$alert(sourceofferCount, 'offerCount:' + response.total, {
                  confirmButtonText: '确定',
                  customClass: 'msgBox',
                  dangerouslyUseHTMLString: true,
                });
              });
            } else if (response.code === 1007) {
              loadingMessage.close();
              this.$nextTick(() => {
                this.$message.warning(response.message);
              });
            } else {
              loadingMessage.close();
              this.$nextTick(() => {
                this.$message.error(response.message);
              });
            }
          })
          .finally(() => {
            this.havingOfferDisabled = false;
          });
      },
      havingOfferDetailClick() {
        var host = window.location.host;
        if (host.indexOf('gourdmobi') != -1) {
          window.open('https://aff.gourdmobi.ichestnutads.com/', '_blank');
        } else if (host.indexOf('ichestnutads') != -1) {
          window.open('https://aff.ichestnutads.com/', '_blank');
        } else {
          window.open('https://aff.bananamobiads.com/', '_blank');
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  .cor337ab7 {
    color: #337ab7;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .header {
    padding-left: 6px;
    @media screen and (min-width: 300px) {
      font-size: 14px;
    }

    @media screen and (min-width: 400px) {
      font-size: 16px;
    }

    @media screen and (min-width: 600px) {
      font-size: 18px;
    }

    @media screen and (min-width: 1000px) {
      font-size: 20px;
    }

    @media screen and (min-width: 1200px) {
      font-size: 24px;
    }

    @media screen and (min-width: 1210px) {
      font-size: 26px;
    }
  }
  .account_item {
    width: 100%;
    height: 70px;
  }
  .button_group {
    padding-left: 10px;
  }
  .row_line {
    border-bottom: 1px solid #ccc;
    margin-bottom: 16px;
  }
  .inline-input {
    padding: 2px;
  }
</style>
<style lang="scss">
  .addAuto {
    .el-dialog__body {
      padding: 30px 60px;
      color: #606266;
      font-size: 14px;
      word-break: break-all;
    }
  }
  .msgBox {
    max-height: 100%;
    overflow: auto !important;
  }
  .el-table .gray-row {
    background-color: gray !important;
  }
</style>
